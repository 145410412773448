<div class="grid-container">
    <div class="modal-body">
        <h2 class="text-center">{{ 'label.add-company' | transloco }}</h2>
        <mat-grid-list cols="2">
            <button *ngFor="let item of companies" class="dropdown-item" (click)="setItem(item.id)">
                <span>
                    <b>
                        {{ item.name }}
                    </b>&nbsp;
                    <small>
                        {{ item.commercial_business}}
                    </small>
                    &nbsp;

                    <small>
                        <b>
                            {{ 'label.city' | transloco }}:
                        </b>
                    </small>
                    {{ item.city }}&nbsp;

                    <small>
                        <b>
                            {{ 'label.country' | transloco }}:
                        </b>
                    </small>
                    {{ item.country }}</span>
            </button>
        </mat-grid-list>
    </div>

    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-danger btn-small" (click)="cancel()">
            {{ 'label.cancel' | transloco }}
        </button>
    </div>
</div>