<div class="grid-container">
    <div class="modal-header">
        <img class="icon-alert" src="../../../../assets/img/png/{{icon}}.png">
    </div>
    <div class="modal-body">
        <mat-grid-list cols="2">
            <h4>{{ title | transloco }}</h4>
            <h5>{{ message | transloco }}</h5>

        </mat-grid-list>
    </div>

    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-danger btn-small" (click)="cancel()">
            {{ 'label.cancel' | transloco }}
        </button>

        <button type="button" class="btn btn-primary btn-small" (click)="confirm()">
            {{ 'label.confirm' | transloco }}
        </button>
    </div>
</div>