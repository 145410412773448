import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Commons } from '../../Commons';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AlertModalComponent } from '../../modals/alert-modal/alert-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from 'src/app/services/storage.service';
import { QuotesService } from 'src/app/services/quotes.service';
import { PublicResourcesService } from 'src/app/services/resources.service';
import { lastValueFrom } from 'rxjs';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

/**
 * 
 * 
  openPreview() {
    const dataToSend = {
      ownerId: this.ownerId,
      quoteId:this.quote.id,
      code:'unknown',
      quoteData: this.quote,
      returnPath: CURRENT_PATH
    };
    this.router.navigate(['Commons.PATH_PUBLIC_QUOTE', dataToSend]);
  }
 * 
 */
@Component({
  selector: 'app-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrl: './view-quote.component.scss',
})
export class ViewQuoteComponent implements OnInit {
  ownerId: number = 0;
  quoteCode: string = ''
  encodedCode: string = ''
  @ViewChild('quoteDetails', { static: false }) quoteDetails!: ElementRef;
  @ViewChild('termsDetails', { static: false }) termsDetails!: ElementRef;
  printFormat: string = 'a4'
  quoteData: any = null
  loading = false
  logoUrl: any
  loadingLogo: boolean = false
  DF_LOGO = environment.ownerId != 0 ? Commons.DF_COMPANY_LOGO : Commons.DF_PRODUCT_LOGO
  alertModal: MdbModalRef<AlertModalComponent> | null = null;
  modalTitle = 'label.quotes'
  owner: any = null
  expired: boolean = false
  terms: any[] = []
  returnPath: string | null = null
  qrCodeUrl = '';
  showQrCode: boolean = false
  subtotal:number = 0
  discAmount:number=0

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private modalService: MdbModalService,
    private quotesService: QuotesService,
    private resourceService: PublicResourcesService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.qrCodeUrl = this.router.url.replace('?returnPath=quotes', '')
    this.owner = Commons.getOwner()
    this.route.queryParams.subscribe(params => {
      this.returnPath = (params['returnPath']) ? params['returnPath'] : null
    });
    this.route.params.subscribe(async params => {
      this.ownerId = params['ownerId']
      this.quoteData = (params['quoteData']) ? params['quoteData'] : null
      if (this.quoteData && this.ownerId != 0) {
        this.loading = true
        await this.loadAdditionalQuoteData()
        this.loading = false
      } else {
        if (this.owner != null && this.ownerId != this.owner.id) {
          this.openModal(this.modalTitle, 'label.wrong-organization-quote', Commons.ICON_WARNING)
          this.router.navigate([Commons.PATH_MAIN])
        }
        this.quoteCode = params['quoteId']
        this.encodedCode = params['code']
        this.loading = true
        this.quotesService.getPublicQuote(this.ownerId, this.quoteCode, this.encodedCode).subscribe(
          {
            next: async (v) => {
              this.quoteData = v
              await this.loadAdditionalQuoteData()
              this.loading = false
            },
            error: (e) => {
              this.openModal(this.modalTitle, 'label.quote-not-found', Commons.ICON_WARNING)
              this.router.navigate([Commons.PATH_MAIN])
            },
            complete: () => { }
          }
        )
      }


    })
  }

  async loadAdditionalQuoteData() {
    this.calcPreValues()
    const response = await lastValueFrom(this.resourceService.getTerms(this.ownerId));
    this.terms = response.terms;
    this.validateExpirationQuote()
    this.loadLogo()
  }

  validateExpirationQuote() {
    if (Commons.validField(this.quoteData) && Commons.validField(this.quoteData.expiration_date)) {
      let expDate = new Date(this.quoteData.expiration_date)
      const currentDate = new Date();
      if (expDate < currentDate) {
        this.openModal(this.modalTitle, 'label.quote-expired', Commons.ICON_WARNING)
        this.expired = true
      }
    }
  }

  onPrintFormatChange(event: any) {
    if (event && event.target && event.target.value) {
      this.printFormat = event.target.value
    }
  }

  generatePDF() {
    this.showQrCode = true
    setTimeout(() => {
      const MAXHEIGHT = 1800
      const element = this.quoteDetails.nativeElement;
      const selectedFormat = this.printFormat;
      
      const divWidth = element.offsetWidth;
      const divHeight = element.offsetHeight;

      let pageWidth: number;
      let pageHeight: number;
      if (selectedFormat === 'a4') {
        pageWidth = 210;
        pageHeight = 297;
      } else if (selectedFormat === 'letter') {
        pageWidth = 216; // 8.5 inches in mm
        pageHeight = 279; // 11 inches in mm
      } else {
        throw new Error('Unsupported format');
      }
      const zoom = ((divHeight < MAXHEIGHT) ? 0.5 : 1);
      const scaleX = (pageWidth * zoom) / divWidth;
      const scaleY = (pageHeight * zoom) / ((divHeight < MAXHEIGHT) ? 964 : divHeight);
      const scale = Math.min(scaleX, scaleY);

      if (element) {
        html2canvas(element).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', selectedFormat);
          const imgWidth = divWidth * scale;
          const imgHeight = divHeight * scale;
          // Calcular la posición para centrar la imagen en la página
          const xOffset = (pageWidth - imgWidth) / 2;
          const yOffset = ((divHeight < MAXHEIGHT) ? 10 : 0); // Alinear en la parte superior

          pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);
          pdf.save(`${this.quoteData.code}.pdf`);
        });
      }
      this.showQrCode = false
    }, 100); // Ajusta el tiempo si es necesario
  }

  get currencyFormat() {
    return (this.quoteData.currency.toLocaleUpperCase() == 'CLP') ? '.0' : '.2-2'
  }

  loadLogo() {
    this.loadingLogo = true
    this.loading = true
    this.storageService.getCompanyLogoByOwnerId(this.ownerId).subscribe(
      {
        next: async (v) => {
          this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(v))
          this.loadingLogo = false
          this.loading = false
        },
        error: (e) => {
          this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(this.DF_LOGO)
          this.loadingLogo = false
          this.loading = false
        },
        complete: () => { }
      }
    )
  }

  openModal(title: string, message: string, icon: string) {
    this.alertModal = this.modalService.open(AlertModalComponent, {
      data: { title: title, message: message, icon: icon },
    })
  }

  get loadingGif() {
    return Commons.getLoadingGif(false)
  }

  get companyAddress() {
    if (Commons.validField(this.quoteData) && Commons.validField(this.quoteData.owner) && Commons.validField(this.quoteData.owner.config)) {
      return this.quoteData.owner.config.address + ', ' + this.quoteData.owner.config.city + ', ' + this.quoteData.owner.config.country
    }
    return ''
  }

  get companyPhone() {
    if (Commons.validField(this.quoteData) && Commons.validField(this.quoteData.owner) && Commons.validField(this.quoteData.owner.config)) {
      return this.quoteData.owner.config.contact_phone
    }
    return ''
  }

  get companyMail() {
    if (Commons.validField(this.quoteData) && Commons.validField(this.quoteData.owner) && Commons.validField(this.quoteData.owner.config)) {
      return this.quoteData.owner.config.contact_mail
    }
    return ''
  }

  get companyName() {
    if (Commons.validField(this.quoteData) && Commons.validField(this.quoteData.owner) && Commons.validField(this.quoteData.owner.config)) {
      return this.quoteData.owner.config.company_name
    }
    return ''
  }

  get termSelected() {
    if (this.terms) {
      return this.terms.find(termSelected => termSelected.id == 2)
    }
    return null
  }

  calcPreValues(){
    if(Commons.validField(this.quoteData)){
      for (let item of this.quoteData.details) {
        this.subtotal = this.subtotal + (item.quantity * item.unit_price)
      }
      this.discAmount = Math.round(((this.subtotal * (this.quoteData.discount / 100)) * 10) / 10)
      this.discAmount = Math.round(((this.subtotal * (this.quoteData.discount / 100)) * 10) / 10)
    }
  }

  goBack() {
    this.router.navigate([this.returnPath])
  }

}
