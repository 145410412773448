<div class="grid-container">
    <div class="modal-body">
        <img class="icon-alert" src="../../../../assets/img/png/{{icon}}.png">
        <mat-grid-list cols="2">
            <h4 class="text-center">{{ title | transloco }}</h4>
            <h5 class="paragraph-text">{{ message | transloco }}</h5>

        </mat-grid-list>
    </div>

    <div class="modal-footer">

        <button type="button" class="btn btn-primary btn-small" (click)="modalRef.close()">
            {{ 'label.accept' | transloco }}
        </button>
    </div>
</div>