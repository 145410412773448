import { Component } from '@angular/core';

@Component({
  selector: 'app-publications-layout',
  templateUrl: './publications-layout.component.html',
  styleUrls: ['./publications-layout.component.scss']
})
export class PublicationsLayoutComponent {

  constructor() { }

  ngOnInit(): void {
    const body = document.querySelector('body');
    body?.classList.remove('with-bg');
  }

}
