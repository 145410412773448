import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';

const routes: Routes = [];

@NgModule({
  imports: [],
  exports: []
})
export class SharedRoutingModule { }
