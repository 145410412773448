import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Commons } from '../shared/Commons';

@Injectable({
    providedIn: 'root'
})
export class QuotesService {
    url: string = environment.productBackendEndpoint + '/quotes'

    constructor(private http: HttpClient) { }

    get(id: number) {
        const url = this.url + '/' + id
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: Commons.sessionCredentials()
            })
        }
        return this.http.get<any>(url, options)
    }

    getPublicQuote(ownerId: number,quoteCode:string,encodedCode:string) {
        const url = this.url + '/' + ownerId+ '/' + quoteCode+ '/' + encodedCode
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }
        return this.http.get<any>(url, options)
    }

    update(request: any) {
        const url = this.url + '/' + request.code
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: Commons.sessionCredentials(),
                xOrigin: environment.baseUrl
            })
        }
        return this.http.put<any>(url, request, options)
    }

    post(request: any) {
        const url = this.url
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: Commons.sessionCredentials(),
                xOrigin: environment.baseUrl
            })
        }
        return this.http.post<any>(url, request, options)
    }

    delete(id: any) {
        const url = this.url + '/' + id
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: Commons.sessionCredentials()
            })
        }
        return this.http.delete<any>(url, options)
    }

    index(status: number, page: number, limit: number, code: string | null, filters: string | null) {
        const url = this.url
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'owner-id': Commons.getOwnerId(null) + '',
                Authorization: Commons.sessionCredentials()
            })
        }
        if (code != null) {
            if (filters != null) {
                return this.http.get<any>(url + '?status=' + status + '&page=' + page + '&limit=' + limit + '&code=' + code + '&filters=' + filters, options)
            } else {
                return this.http.get<any>(url + '?status=' + status + '&page=' + page + '&limit=' + limit + '&code=' + code, options)
            }
        } else {
            if (filters != null) {
                return this.http.get<any>(url + '?status=' + status + '&page=' + page + '&limit=' + limit + '&filters=' + filters, options)
            } else {
                return this.http.get<any>(url + '?status=' + status + '&page=' + page + '&limit=' + limit, options)
            }
        }

    }

}
