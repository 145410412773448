import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent {

  constructor() { }

  ngOnInit(): void {
    const body = document.querySelector('body');
    if (environment.withBackgroundBody) {
      body?.classList.add('with-bg');
    } else {
      body?.classList.remove('with-bg');
    }
  }

}
