import { TitleCasePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { NoSpecialCharactersPipe } from "./no-special-characters.pipe";

@Pipe({
    name: 'nameformat'
})
export class NameFormatPipe implements PipeTransform {
    transform(arg: string | null): string {
        if(arg){
            const noSpChars: NoSpecialCharactersPipe = new NoSpecialCharactersPipe()
            const titecase: TitleCasePipe = new TitleCasePipe()
            return titecase.transform(noSpChars.transform(arg))
        }
        return ''
    }
}