<div *ngIf="!loading" class="container">


    <div id="quote-details" #quoteDetails>
        <div class="receiver-section">
            <div class="row">
                <div class="col-6">
                    <img *ngIf="loadingLogo" class="img-loading mb-4 border-bottom" [src]="loadingGif">
                    <img *ngIf="!loadingLogo && logoUrl==DF_LOGO" class="logo-img" src="{{logoUrl}}">
                    <img *ngIf="!loadingLogo && logoUrl!=DF_LOGO" class="logo-img" [src]="logoUrl">
                </div>
                <div class="col-6">
                    <p><strong>{{'label.contact'|transloco}}:</strong> {{ companyMail }}</p>
                    <p><strong>{{'label.phone'|transloco}}:</strong> {{ companyPhone }}</p>
                    <p><strong>{{'label.address'|transloco}}:</strong> {{ companyAddress }}</p>
                </div>
            </div>
            <h3>{{'label.quote'|transloco}}</h3>
            <h4 *ngIf="expired" class="expired-note">{{'label.quote-expired' | transloco}}</h4>
            <div class="row">
                <div class="col-12"><strong>{{'label.code'|transloco}}:</strong> {{ quoteData.code }}</div>
                <div class="col-6"><strong>{{'label.date-sent'|transloco}}:</strong> {{ quoteData.date_sent | date:
                    'dd-MM-yyyy' }}</div>
                <div class="col-6 text-right"><strong>{{'label.expiration-date'|transloco}}:</strong> {{
                    quoteData.expiration_date | date: 'dd-MM-yyyy' }}
                </div>
            </div>
            <h4>{{'label.receiver'|transloco}}</h4>
            <div class="row">
                <div class="col-6"><strong>{{'label.name'|transloco}}</strong> {{ quoteData.receiver.name }}</div>
                <div class="col-6 text-right"><strong>{{'label.email'|transloco}}</strong> {{ quoteData.receiver.email }}
                </div>
            </div>
        </div>
    
        <h4>{{'label.details'|transloco}}:</h4>
        <table class="invoice-table">
            <tr>
                <th>{{'label.description'|transloco}}</th>
                <th class="text-center">{{'label.quantity'|transloco}}</th>
                <th class="text-right">{{'label.unit-price'|transloco}}</th>
                <th class="text-right">{{'label.subtotal'|transloco}}</th>
            </tr>
            <tr *ngFor="let detail of quoteData.details">
                <td>{{ detail.description }}</td>
                <td class="text-center">{{ detail.quantity }}</td>
                <td class="text-right">{{ detail.unit_price | number:currencyFormat }}{{ ' ' }}{{ quoteData.currency }}</td>
                <td class="text-right">{{ detail.subtotal | number:currencyFormat }}{{ ' ' }}{{ quoteData.currency }}</td>
            </tr>
        </table>
        <table class="table-resume">
            <tr>
                <td class="text-right"><strong>{{'label.subtotal'|transloco}}</strong></td>
                <td class="text-right">{{ subtotal | number:currencyFormat }}{{ ' ' }}{{ quoteData.currency }}
                </td>
            </tr>
            <tr>
                <td class="text-right"><strong>{{'label.discount'|transloco}}{{' '+quoteData.discount+'%'}}</strong></td>
                <td class="text-right">{{ discAmount | number:currencyFormat }}{{ ' ' }}{{ quoteData.currency }}
                </td>
            </tr>
            <tr>
                <td class="text-right"><strong>{{'label.net-worth'|transloco}}</strong></td>
                <td class="text-right">{{ quoteData.net_worth | number:currencyFormat }}{{ ' ' }}{{ quoteData.currency }}
                </td>
            </tr>
            <tr>
                <td class="text-right"><strong>{{'label.tax'|transloco}}</strong></td>
                <td class="text-right">{{ (quoteData.total - quoteData.net_worth) | number:currencyFormat }}{{ ' ' }}{{
                    quoteData.currency }}
                </td>
            </tr>
            <tr>
                <td class="text-right"><strong>{{'label.total'|transloco}}</strong></td>
                <td class="text-right">{{ quoteData.total | number:currencyFormat }}{{ ' ' }}{{ quoteData.currency }}</td>
            </tr>
        </table>
    
        <h4>{{('label.notes'|transloco) | titlecase}}:</h4>
        <div class="card mb-3 notes" [innerHTML]="quoteData.notes">
        </div>
        <div *ngIf="showQrCode" style="display: flex; align-items: center; justify-content: flex-end;">
            <h5 style="margin-right: 10px;">{{'label.verify-quote' | transloco}}</h5>
            <qrcode [qrdata]="qrCodeUrl"></qrcode>
          </div>
          
    </div>

    <div id="terms-details" #termsDetails *ngIf="termSelected != null" class="row">
        <div class="col-lg-12">

            <div class="billing-details mt-2">
                <h4>{{ termSelected.name }}</h4>

                <div *ngFor="let item of termSelected.detail" class="term-item">
                    <h5 class="term-title">{{ item.title }}</h5>
                    <p class="term-content">{{ item.content }}</p>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="!loading" class="container">
    <div class="row">
        <div class="col-6">
            <h6>{{'label.format'|transloco}}:</h6>
            <select [(ngModel)]="printFormat" (ngModelChange)="onPrintFormatChange($event)">
                <option value="a4">A4</option>
                <option value="letter">Letter</option>
            </select>
        </div>

        <div class="col-6 btn-download"><button class="btn btn-primary btn-extra-small"
                (click)="generatePDF()">{{'label.generate-pdf'|transloco}}</button>
        </div>

        <div *ngIf="returnPath != null" class="col-12 text-right my-2"><button class="btn btn-warning btn-extra-small"
                (click)="goBack()">{{'label.go-back'|transloco}}</button>
        </div>
    </div>

    <br>
</div>
<div *ngIf="loading" class="billing-details mt-5">
    <img class="img-loading" [src]="loadingGif">
</div>