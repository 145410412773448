import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { OwnerConfig } from 'src/app/shared/interfaces/core/owner-config';
import { Commons } from 'src/app/shared/Commons';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

declare var google: any;

/*
Can BE:
  ...
  templateUrl: './custom/{CUSTOM_COMPONENT_NAME}.component.html'
  styleUrls: ['./custom/{CUSTOM_COMPONENT_NAME}.component.scss']
  ...
*/
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

  version = environment.appVersion

  ownerDetail: OwnerConfig = Commons.emptyOwnerConfig()

  privacyPolicyPath: string = Commons.PATH_TERMS + '/' + Commons.TERM_CODES[0].code
  termConditionsPath: string = Commons.PATH_TERMS + '/' + Commons.TERM_CODES[1].code
  termSalesPath: string = Commons.PATH_TERMS + '/' + Commons.TERM_CODES[2].code
  cookiePolicyPath: string = Commons.PATH_TERMS + '/' + Commons.TERM_CODES[3].code

  PATH_ABOUT = '/' + Commons.PATH_ABOUT
  PATH_CONTACT = '/' + Commons.PATH_CONTACT
  YOUTUBE_LINK = environment.social.youtube
  INSTAGRAM_LINK = environment.social.instagram
  TWITTER_LINK = environment.social.x
  FACEBOOK_LINK = environment.social.facebook
  LINKEDIN_LINK = environment.social.linkedin

  anio: Date = new Date();
  ciaName: string = Commons.SDX
  companyName = this.ciaName
  appName = environment.appName
  providerWeb = environment.providerWeb

  mapEnabled = environment.map.enabled
  logoEnabled = environment.withFooterLogo
  @ViewChild('map') mapElement: any;
  map: any;
  logoUrl: any
  loadingLogo: boolean = false
  DF_LOGO = environment.ownerId != 0 ? Commons.DF_COMPANY_LOGO : Commons.DF_PRODUCT_LOGO
  showAlert: boolean = false
  getScreenWidth: any;
  mobileWidth: number = Commons.MOBILE_WIDTH
  /* BEGIN | CUSTOM VARS */
  /* END | CUSTOM VARS */

  constructor(
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private router: Router,
    /* BEGIN | CUSTOM ARGS */
    /* END | CUSTOM ARGS */
  ) {

  }

  ngAfterViewInit(): void {
    this.getScreenWidth = window.innerWidth
    if (Commons.getOwner()) {
      this.reviewPlan(Commons.getOwner().plan_category)
    }
    this.initMap();
  }

  ngOnInit(): void {
    /* BEGIN | CUSTOM CODE */
    /* END | CUSTOM CODE */
    this.ownerDetail = (Commons.getOwner() != null) ? Commons.getOwner().config : Commons.getDefaultConfig()
    this.companyName = this.ownerDetail.company_name
    this.loadLogo()
  }

  reviewPlan(planCategory: string | undefined) {
    if (Commons.validField(planCategory)) {
      const namesArray = Commons.PLAN_CATEGORIES.map(obj => obj.value);
      const index = namesArray.indexOf(planCategory);
      if (index === -1) {
        this.showAlert = true
      } else {
        this.showAlert = false
      }
    }
  }

  initMap() {
    if (environment.map.enabled) {
      let mapOptions = {
        center: new google.maps.LatLng(environment.map.lat, environment.map.lng),
        zoom: 17,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      const marker = new google.maps.Marker({
        position: mapOptions.center,
        title: this.location
      });
      marker.setMap(this.map);
    }
  }

  get location(): string {
    const address = this.ownerDetail.address
    const city = this.ownerDetail.city
    const country = this.ownerDetail.country
    return (Commons.validField(address) && address !== '') ? address + ', ' + city + ', ' + country.toUpperCase() : city + ', ' + country.toUpperCase()
  }

  loadLogo() {
    if (Commons.validField(Commons.getOwner())) {
      this.loadingLogo = true
      this.storageService.getCompanyLogoByOwnerId(Commons.getOwner().id).subscribe(
        {
          next: async (v) => {
            this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(v))
            this.loadingLogo = false
          },
          error: (e) => {
            this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(this.DF_LOGO)
            this.loadingLogo = false
          },
          complete: () => { }
        }
      )
    } else {
      this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(this.DF_LOGO)
    }
  }

  get loadingGif() {
    return Commons.getLoadingGif(false)
  }

  get showAlertComponent(){
    const isLoginPage = this.router.isActive(Commons.PATH_LOGIN, true);
    return !isLoginPage && this.showAlert
  }

  get enableSocialLink(): boolean {
    const socialLinks = [this.YOUTUBE_LINK, this.INSTAGRAM_LINK, this.TWITTER_LINK, this.FACEBOOK_LINK, this.LINKEDIN_LINK];
    return socialLinks.some(link => link !== '');
  }

  get isMobile() {
    return this.getScreenWidth < this.mobileWidth
  }
  
  /* BEGIN | CUSTOM FUNCTIONS */
  /* END | CUSTOM FUNCTIONS */
}
