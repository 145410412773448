<nav class="navbar navbar-expand-lg navbar-dark bg-admin-home w-100 navigation" id="navbar">
  <div class="container">
    <a class="navbar-brand font-weight-bold text-white nav-link" routerLink="/">{{'label.home' | transloco | uppercase }}</a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar"
      aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="main-navbar" #mainNavbar>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" routerLink="{{PATH_PRODUCT}}">{{'label.plans'|transloco}}</a>
        </li>

        <li class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" routerLink="{{PATH_PUBLICATIONS}}">{{'label.publications'|transloco}}</a>
        </li>

        <li class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" routerLink="{{PATH_QUOTES}}">{{'label.quotes'|transloco}}</a>
        </li>

        <li *ngIf="withoutCompany" class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" (click)="toMyCompany()">{{'label.create-company-header'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth && !withoutCompany" class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" (click)="toMyCompany()">{{'label.my-organization'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth && !withoutCompany" class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" routerLink="{{PATH_CLIENTS}}">{{'label.clients'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth && !withoutCompany" class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" (click)="toPurchases()">{{'label.payment-history'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth && !withoutCompany" class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" (click)="toLicences()">{{getPlan()|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth && !withoutCompany" class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" routerLink="{{PATH_CONFIG}}">{{'label.configuration'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link text-white" (click)="openLanguageModal()">{{'language.title'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth >= mobileWidth" class="nav-item dropdown dropdown-slide">
          <a *ngIf="sessionIsOpen && !withoutCompany" class="nav-link dropdown-toggle text-white" href="#"
            id="navbarDropdown4" role="button" data-delay="350" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            {{getCompanyName()}}
          </a>

          <a *ngIf="!sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ 'label.login-invite' | transloco }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown4">
            <li>
              <a
                (click)="toMyCompany()">{{(withoutCompany)?'label.create-company-header':'label.my-organization'|transloco}}</a>
            </li>
            <li *ngIf="!withoutCompany"><a routerLink="{{PATH_CLIENTS}}">{{'label.clients'|transloco}}</a></li>
            <li *ngIf="!withoutCompany"><a (click)="toPurchases()">{{'label.payment-history'|transloco}}</a></li>
            <li *ngIf="!withoutCompany"><a (click)="toLicences()">{{getPlan()|transloco}}</a></li>
            <li *ngIf="!withoutCompany"><a routerLink="{{PATH_CONFIG}}">{{'label.configuration'|transloco}}</a></li>
          </ul>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item dropdown dropdown-slide">
          <a *ngIf="sessionIsOpen" class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown4"
            role="button" data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{getCustomerName()}}
          </a>
          <a *ngIf="!sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ 'label.login-invite' | transloco }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown4">
            <li><a routerLink="{{PATH_MY_CUSTOMER}}" (click)="closeNavbar()">{{'label.profile'|transloco}}</a></li>
            <!--li><a (click)="toSupport()" (click)="closeNavbar()">{{'label.support'|transloco}}</a></li-->
            <li><a routerLink="{{PATH_MAIN}}"
                class="btn btn-small btn-transparent" (click)="closeNavbar()">{{'label.out-of-dash'|transloco}}</a></li>
            <li *ngIf="!sessionIsOpen"><a routerLink="{{PATH_LOGIN}}"
                class="btn btn-small btn-main btn-block" (click)="closeNavbar()">{{'login.title'|transloco}}</a></li>
            <li *ngIf="sessionIsOpen"><a (click)="closeSession()"
                class="btn btn-small btn-main btn-block" (click)="closeNavbar()">{{'label.close'|transloco}}</a></li>
          </ul>
        </li>

      </ul>
    </div>
    <!-- Navbar-collapse -->

    <ul class="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">

      <li class="list-inline-item"><a href="#"></a></li>
      <li class="dropdown cart-nav dropdown-slide list-inline-item">
        <a href="#" (click)="openLanguageModal()" class="dropdown-toggle cart-icon" data-toggle="dropdown" data-hover="dropdown">
          <i class="tf-ion-ios-world mr-3"></i>
        </a>
      </li>
    </ul>

    <ul class="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">

      <li class="list-inline-item"><a href="#"></a></li>
      <li class="dropdown cart-nav dropdown-slide list-inline-item">
        <a href="#" class="dropdown-toggle cart-icon" data-toggle="dropdown" data-hover="dropdown">
          <i class="tf-ion-ios-person mr-3"></i>
        </a>
        <div class="dropdown-menu cart-dropdown">
      <li *ngIf="sessionIsOpen">
        <h3>{{getCustomerName()}}</h3>
      </li>
      <li><a routerLink="{{PATH_MY_CUSTOMER}}">{{'label.profile'|transloco}}</a></li>
      <!--li><a (click)="toSupport()">{{'label.support'|transloco}}</a></li-->
      <li><a routerLink="{{PATH_MAIN}}" class="btn btn-small btn-transparent">{{'label.out-of-dash'|transloco}}</a></li>
      <li *ngIf="sessionIsOpen && enableWebmailSiteLink"><a href="{{webmailSite}}" target="_blank" class="btn btn-small btn-transparent">{{'label.open-mail-site'|transloco}}</a></li>
      <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
        <a routerLink="{{PATH_LOGIN}}" class="btn btn-small btn-main btn-block">{{'login.title'|transloco}}</a>
      </div>
      <div *ngIf="sessionIsOpen" class="text-center cart-buttons mt-3">
        <a (click)="closeSession()" class="btn btn-small btn-main btn-block">{{'label.close'|transloco}}</a>
      </div>
  </div>
  </li>
  </ul>
  </div>
</nav>
