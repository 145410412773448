import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PublicResourcesService {
    url: string = environment.productBackendEndpoint

    constructor(private http: HttpClient) { }

    getTerms(ownerId:number): Observable<any> {
        if(environment.ownerId != 0){
            return this.http.get<any>("assets/json/terms.json");
        }
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        return this.http.get<any>(this.url + '/mycompany/terms/'+ownerId, options)
    }

    getJsonFile(arg: string): Observable<any> {
        if (!arg.endsWith('.json')) {
            arg = arg + '.json';
        }
        return this.http.get<any>("assets/json/" + arg);
    }

}
