import { ChangeDetectorRef, Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from './services/session.service';
import { Commons } from './shared/Commons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  KEEPALIVE_TIME: number = 3
  SESSION_TIME_MINS: number = environment.sessionTimeMins
  IDLE_TIME: number = (this.SESSION_TIME_MINS * 60)


  // add parameters for Idle and Keepalive (if using) so Angular will inject them from the module
  constructor(
    private idle: Idle,
    keepalive: Keepalive,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private titleService: Title,
    private metaService: Meta
  ) {

    keepalive.interval(this.KEEPALIVE_TIME);
    keepalive.onPing.subscribe(() => {
      if (Commons.isGAuthCredentials()) {
        const diffMins = Math.floor((Date.now() - Commons.sessionObject().time) / 60000);
        if (diffMins > this.SESSION_TIME_MINS) {
          //Force close session because google token expires in 1 hour
          this.closeSessionExpToken()
        }
      } else {
        Commons.sessionRenew()
      }
    });

    idle.setIdle(this.IDLE_TIME); // Define el tiempo de inactividad en segundos.
    idle.setTimeout(1); // Define el tiempo de espera en segundos antes de que se dispare el evento timeout.
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { });
    idle.onTimeout.subscribe(() => {
      this.closeSession()
    });
    idle.onIdleStart.subscribe(() => { });
    idle.onTimeoutWarning.subscribe((countdown) => { });

    this.sessionService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch();
      } else {
        idle.stop();
      }
    })
  }

  reset() {
    this.idle.watch();
  }

  ngOnInit(): void {
    this.loadMetaTags()
    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  closeSession() {
    if (Commons.sessionIsOpen()) {
      this.sessionService.setUserLoggedIn(false);
      Commons.sessionClose()
      this.router.navigate([Commons.PATH_LOGIN], { queryParams: { arg: 'closed' } })
    }
  }

  closeSessionExpToken() {
    if (Commons.sessionIsOpen()) {
      this.sessionService.setUserLoggedIn(false);
      Commons.sessionClose()
      this.router.navigate([Commons.PATH_LOGIN], { queryParams: { arg: 'expToken' } })
    }
  }

  loadMetaTags() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {

        let rt = this.getChild(this.route)

        rt.data.subscribe((data: any) => {
          this.titleService.setTitle(data.title)

          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }

          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }

          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }

          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }

          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }

          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }


          if (data.content) {
            this.metaService.updateTag({ content: data.content, name: 'keywords' })
          } else {
            this.metaService.removeTag("content")
          }

        })

      })

  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}
