export const environment = {
  baseUrl: 'https://publinex-dev.softdirex.com',
  // Used for redirextion from client sites
  productSite: 'https://publinex-dev.softdirex.com',
  enableWebmailSiteLink: false,
  // https://mail.google.com/
  webmailSite: 'https://mail.hostinger.com/',
  production: true,
  appVersion: '1.1.3',
  localeKey: 'NIKqPYrGejeDFCcRC0Y82V',
  appName: 'Publinex',
  appDescription: 'Manage publications and content with themes associated with your organization',
  appTags: 'posts,publications,notices,blog,organizations,corporative blogs,rrss,newsletter,news,noticias,blogs corporativos,publicaciones,empresas,sitios web,website,web',
  footerInfo: 'Softdirex - Innovate your digital tools',
  officeLocation: 'La Serena - Chile',
  contactEmail: 'sales@softdirex.com',
  contactPhone: '+569 9867 2957',
  orgName: 'Softdirex',
  // Direccion web de softdirex
  providerWeb: 'https://softdirex.com/',
  // OWNER_ID Asociado al producto, si está en 0 carga datos de softdirex
  ownerId: 0,
  productId: 1,
  // BEGIN - Owner config default
  dfConfigCompanyName: 'Publinex',
  dfConfigSlogan: 'Moderniza tus herraminetas digitales',
  dfConfigAbout: 'Publinex es un producto desarrollado por Softdirex para promover la visualización de empresas al instante, ya no necesitarás configurar puertos ni direcciones web, solo seleccionando el plan que mejor se adapte a tus necesidades, somos una empresa que en poco tiempo ha logrado tomar a nuestros clientes al siguiente nivel tecnológico, manteniendo nuestras herramientas informáticas en constante evolución para estar al día con el creciente avance tecnológico, nuestro trabajo ha sido arduo pero los logros han sido gratificantes, nos enfocamos en responder a nuestros clientes en sus requerimientos tecnológicos con valor agregado para seguir creciendo. Contamos con un área de investigación para crear nuevas soluciones de vanguardia.',
  dfConfigMission: 'Nuestra misión es promover y brindar a las pequeñas y medianas empresas mejores herramientas específicas de administración de software que las ayudarán a competir de manera más efectiva en el mercado, entregando las mejores herramientas digitales para hacer públicos sus productos o servicios al instante, agilizando la forma en que comparte su información con el mundo según los estándares digitales actuales.',
  dfConfigVision: 'Todos debemos tener acceso a sistemas de información modernos, sin importar el tamaño de nuestros proyectos, cualquier implementación efectiva de tecnología contribuirá a un mejor desarrollo y a la larga traerá grandes ganancias.',
  dfConfigContactPhone: '+569 9867 2957',
  dfConfigContactMail: 'jleiva@softdirex.com',
  dfConfigAddress: '',
  dfConfigCity: 'La Serena',
  dfConfigCountry: 'Chile',
  dfConfigTermsFilename: 'terms.json',
  dfConfigLang: 'es',
  dfConfigDaysValidQuote: 15,
  dfConfigDefaultNote: '',
  dfConfigTaxPercentage: 0,
  // END - Owner config default
  productBackendEndpoint: 'https://api-publinex-dev.softdirex.com/v1',
  coreFrontendEndpoint: 'https://escritorio-dev.softdirex.com/',
  pwdRegex: '^[\x20-\x7E]{8,30}$',
  rutRegex: '\\b[0-9|.]{1,10}\\-[K|k|0-9]',
  passportRegex: '[a-zA-Z0-9-]{6,100}',
  dniRegex: '[a-zA-Z0-9-]{6,100}',
  documentDataRegex: '[a-zA-Z0-9-]{6,100}',
  namesRegex: '[a-zA-Z\u00C0-\u00ff-\' ]{2,90}',
  phonesRegex: '[0-9+() -]{8,35}',
  addressRegex: '[a-zA-Z\u00C0-\u00ff-.@ 0-9]{2,90}',
  rrssRegex: '[a-zA-Z-/:.#@_0-9%=?&]{1,90}',
  obsRegex: '[a-zA-Z/%\u00C0-\u00ff-.,&\'\n": 0-9]{2,1000}',
  aboutRegex: '[a-zA-Z/¡!()@=?¿%\u00C0-\u00ff&-.,;\'\n": 0-9]{2,5000}',
  sessionTimeMins: 55,
  recaptcha: {
    siteKey: '6LcSKvEkAAAAAFfhUa9wcIhCFAd_P_yZFwN6lRNj',
  },
  withBackgroundBody: false,
  withFooterLogo: false,
  withNavBarLogo: false,
  map: {
    gmapsApiKey: "AIzaSyAvyilehl7RQjrDiHEoTRNI1I3JoLg2KKY",
    lat: -29.958324,
    lng: -71.266277,
    enabled: true
  },
  // Social: dejar en blanco si no existe informacion
  social: {
    youtube: '',
    instagram: '',
    x: '',
    facebook: '',
    linkedin: ''
  },
  googleClientId: '299928096210-g4r6tonbi1g9a47f798cum1muuo32e0q.apps.googleusercontent.com'
};
