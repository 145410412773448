import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent {

  constructor() { }

  ngOnInit() {
    const body = document.querySelector('body');
    body?.classList.remove('with-bg');
  }

}
